// Colors

// Primary colors
$primary: #F2AB27;
$primary-light: #FFC14F;
$primary-dark: #E59500;

// Secondary colors
$secondary: #333333;
$secondary-light: #545252;
$secondary-dark: #222222;

// Gray tones
$white: #FFFFFF;
$gray: #ADAFB4;
$gray-light: #E1DFDF;
$gray-dark: #928F8F;
$background: #F7F7F7;

// Support colors
$error: #D9534F;
$success: #1ABC77;
$info: #1AB2BC;
$warning: #E9D20B;

// Extra Buttons

// LIGHT_YELLOW
$color-light-yellow: #FFC14F33;
$color-light-yellow-alternate: #FFC14F59;

// LIGHT_BLUE
$color-light-blue: #001D4633;
$font-color-light-blue: #001D46E5;
$color-light-blue-alternate: #001D4659;

// LIGHT_GREEN
$color-light-green: #3BA89033;
$font-color-light-green: #3BA890;
$color-light-green-alternate: #3BA89059;

// LIGHT_RED
$color-light-red: #D9534F33;
$color-light-red-alternate: #D9534F59;


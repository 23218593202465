@import './demo';
@import './theme.scss';

#toast-container > div {
  font-family: 'Open Sans', sans-serif !important;
  opacity:1;
  margin-top: 5vh;
  padding: 15px 15px 20px 15px;
  background-image: none;
  border-radius: 8px;
  font-weight: bold;
  font-size: medium;
}

.toast-success {
  background-color: $success !important;
}

.toast-error {
  background-color: $error !important;
}

button.toast-close-button {
  font-size: xx-large;
  font-weight: 300;
  margin-right: 5px;
  max-height: 0.5vmin;

  &:hover {
    color: $white;
  }
}

* {
  font-family: 'Open Sans', sans-serif !important;
}

::-webkit-scrollbar {
  width: 0.5vmin;
  border-radius: 8px !important;
}

::-webkit-scrollbar-thumb {
  background: $gray-dark !important;
  border-radius: 8px !important;
}

.is-invalid {
  color: $error !important;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-dialog {
  width: 482px !important;
}

.text-align-center {
  text-align: center;
}

p {
  font-size: 20px !important;
  line-height: 130%;
  font-weight: 400;
  color: $secondary !important;
  margin: 0;
}

.p2 {
  font-size: 18px !important;
}


.p3 {
  font-size: 16px !important;
}

.p4 {
  font-size: 14px !important;
}

.small {
  font-size: 12px !important;
}

h1 {
  font-size: 24px !important;
  color: $secondary-light !important;
  line-height: 140% !important;

}
h2 {
  font-size: 22px !important;
  line-height: 140% !important;
  color: $secondary-light !important;
}

.align-error-text {
  transform: translateY(2px);
  margin-left: 5px;
}

.default-border-radius {
  border-radius: 8px !important;
}

.regular {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.invalid {
  color: $error !important;
  padding: 0px 0px 15px;
}

.form-control {
  border-color: $gray-dark;
  padding: 21px 16px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
}

.form-control:focus {
  border-color: $primary;
}

.separator {
  height: 100vh;
  width: 8px;
  background-color: $secondary;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: $error;
}

.invalid-feedback {
  color: $error;
  display: inline !important;
}


@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// Remove outline on desktops
@media(min-width: 720px) {
  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

// Fix horizontal scrollbar issue when .dropdown-menu-right element is shown
.layout-wrapper {
  overflow: hidden;
}

// *******************************************************************************
// * Page transition

.router-transitions {
  opacity: 1;
  transition: opacity .2s;

  body.app-loading & {
    opacity: .75;
  }
}

// *******************************************************************************
// * Animations

@include keyframes(ngFloatingElementAnimation) {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.default-style,
.material-style {
  .tooltip,
  .popover {
    animation: ngFloatingElementAnimation .25s;
  }
  .dropdown-menu {
    animation: ngFloatingElementAnimation .1s;
  }
}

// *******************************************************************************
// * Modal animations

@include keyframes(ngModalBackdropAnimation) {
  0% { opacity: 0; }
  100% { opacity: .5; }
}
@include keyframes(ngMaterialModalBackdropAnimation) {
  0% { opacity: 0; }
  100% { opacity: .2; }
}
@include keyframes(ngDefaultModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@include keyframes(ngTopModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@include keyframes(ngSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@include keyframes(ngRtlSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@include keyframes(ngFillInModalAnimation) {
  0% {
    opacity: 0;
    transform: scale(.5, .5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

ngb-modal-backdrop.modal-backdrop {
  opacity: .5;
  animation: ngModalBackdropAnimation .15s ease-out;
}

ngb-modal-window {
  &.modal .modal-dialog {
    animation: ngDefaultModalAnimation .15s ease-out;
  }

  &.modal-top .modal-dialog {
    animation: ngTopModalAnimation .15s ease-out;
  }

  &.modal-slide .modal-dialog {
    animation: ngSlideModalAnimation .15s ease-out;
  }

  &.modal-fill-in .modal-dialog {
    animation: ngFillInModalAnimation .15s ease-out;
  }

  [dir=rtl] &.modal-slide .modal-dialog {
    animation: ngRtlSlideModalAnimation .15s ease-out;
  }
}

.material-style ngb-modal-backdrop.modal-backdrop {
  opacity: .2;
  animation: ngMaterialModalBackdropAnimation .15s ease-out;
}

// Prevent horizontal scrollbar
[dir=rtl] .dz-hidden-input {
  right: 0 !important;
  left: auto !important;
}

// *******************************************************************************
// * Accordion

ngb-accordion .card-header .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  border: 0;
  font-size: .894rem;
}

.ngb-accordion-with-icon .card-header .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

// *******************************************************************************
// * Typeahead

ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}

// *******************************************************************************
// * Placeholder

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $gray-dark;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $gray-dark;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: $gray-dark;
}

.genericErrorModal .modal-dialog {
  word-wrap: break-word;
}

.offset-paginator-blipchat-opened {
  margin-right: 6vmin;
}

//Cores de textos:
.primary-text-color{
  color: $primary;
}
.primary-light-text-color{
  color: $primary-light !important;
}
.primary-dark-text-color{
  color: $primary-dark !important;
}
.secondary-text-color{
  color: $secondary !important;
}
.secondary-light-text-color{
  color: $secondary-light !important;
}
.secondary-dark-text-color{
  color: $secondary-dark !important;
}
.white-text-color{
  color: $white !important;
}
.gray-text-color{
  color: $gray !important;
}
.gray-light-text-color{
  color: $gray-light !important;
}
.gray-dark-text-color{
  color: $gray-dark !important;
}
.background-text-color{
  color: $background !important;
}
.error-text-color{
  color: $error !important;
}
.success-text-color{
  color: $success !important;
}
.info-text-color{
  color: $info !important;
}
.warning-text-color{
  color: $warning !important;
}
.color-light-yellow-text-color{
  color: $color-light-yellow !important;
}
.color-light-yellow-alternate-text-color{
  color: $color-light-yellow-alternate !important;
}
.color-light-blue-text-color{
  color: $color-light-blue !important;
}
.font-color-light-blue-text-color{
  color: $font-color-light-blue !important;
}
.color-light-blue-alternate-text-color{
  color: $color-light-blue-alternate !important;
}
.color-light-green-text-color{
  color: $color-light-green !important;
}
.font-color-light-green-text-color{
  color: $font-color-light-green !important;
}
.color-light-green-alternate-text-color{
  color: $color-light-green-alternate !important;
}
.color-light-red-text-color{
  color: $color-light-red !important;
}
.color-light-red-alternat-text-color{
  color: $color-light-red-alternate !important;
}

